import React,{useState,useEffect} from 'react';
import logo from '../img/smalllogo.svg';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import 'react-dates/lib/css/_datepicker.css';
import BooknowModal from './BooknowModal';
import './Booknow.css'

function Booknow(props) {
	var today = new Date();
	var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
	
	const [checkindate,setCheckin] = useState(require('dateformat')(tomorrow,'yyyy-mm-dd'));
	const [show, setShow] = useState(false);
	const [checkoutdate,setCheckout] = useState(checkindate);
	const [roomtypes, setRoomtypes] = useState('STDT');
	const [name,setName] = useState('');
	const [email,setEmail] = useState('');
	const [tel,setTel] = useState('');
	const [rooms,setRooms] = useState(1);
	const [remark,setRemark] = useState('-');
	const [checked,setChecked] = useState(false);
	const [startdate,setStartDate] = useState(moment());
	const [enddate,setEndDate] = useState(moment());
	var dateFormat = require('dateformat');
	const [focusedInput,setFocusedInput] = useState(null);

	const [showModal, setShowModal] = useState(false);
	
	dateFormat.i18n = props.language === 'TH' ? {
		    dayNames: [
		        'อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.',
		        'อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'
		    ],
		    monthNames: [
		        'ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.',
		        'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
		    ],
		    timeNames: [
		        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
		    ]
		}
		: props.language === 'JP' ? {
		    dayNames: [
		        '日', '月', '火', '水', '木', '金', '土',
		        '日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日',
		    ],
		    monthNames: [
		        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
		        '１月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'
		    ],
		    timeNames: [
		        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
		    ]
		}
		: props.language === 'CN' ? {
		    dayNames: [
		        '周日', '周一', '周二', '周三', '周四', '周五', '周六',
		        '星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'
		    ],
		    monthNames: [
		        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
		        '１月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'
		    ],
		    timeNames: [
		        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
		    ]
		}
		: props.language === 'ZN' ? {
		    dayNames: [
		        '週日', '週一', '週二', '週三', '週四', '週五', '週六',
		        '星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'
		    ],
		    monthNames: [
		        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
		        '１月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'
		    ],
		    timeNames: [
		        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
		    ]
		}
		: {
		    dayNames: [
		        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
		        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
		    ],
		    monthNames: [
		        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
		        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
		    ],
		    timeNames: [
		        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
		    ]
		}
	var roomPrices = [
		{key: 'STDT',price: 790 },
		{key: 'STDTB',price: 990},
		{key: 'STDTBD',price: 1490},
		{key: 'STDD',price: 790 },
		{key: 'STDDB',price: 990},
		{key: 'STDDBD',price: 1490},
		{key: 'CLST',price: 680 },
		{key: 'CLSTB',price: 880},
		{key: 'CLSTBD',price: 1380},
	];

	var roomtypesEN = [
		{key: 'STDT',name: 'Standard Twins Room (2 guests)'},
		{key: 'STDTB',name: 'Standard Twins Room with Breakfast (2 guests)'},
		{key: 'STDTBD',name: 'Standard Twins Room with Breakfast and Dinner (2 guests)'},
		{key: 'STDD',name: 'Standard Double Room (2 guests)'},
		{key: 'STDDB',name: 'Standard Double Room with Breakfast (2 guests)'},
		{key: 'STDDBD',name: 'Standard Double Room with Breakfast and Dinner (2 guests)'},
		{key: 'CLST',name: 'Classic Twins Room (2 guests)'},
		{key: 'CLSTB',name: 'Classic Twins Room with Breakfast (2 guests)'},
		{key: 'CLSTBD',name: 'Classic Twins Room with Breakfast and Dinner (2 guests)'},
	];

	var roomtypesTH = [
		{key: 'STDT',name: 'ห้องมาตรฐาน เตียงแฝด (2 ท่าน)' },
		{key: 'STDTB',name: 'ห้องมาตรฐาน เตียงแฝด รวมอาหารเช้า (2 ท่าน)'},
		{key: 'STDTBD',name: 'ห้องมาตรฐาน เตียงแฝด รวมอาหารเช้า และ อาหารเย็น (2 ท่าน)'},
		{key: 'STDD',name: 'ห้องมาตรฐาน เตียงเดี่ยว รวมอาหารเช้า (2 ท่าน)'},
		{key: 'STDDB',name: 'ห้องมาตรฐาน เตียงเดี่ยว รวมอาหารเช้า (2 ท่าน)'},
		{key: 'STDDBD',name: 'ห้องมาตรฐาน เตียงเดี่ยว รวมอาหารเช้า และ อาหารเย็น (2 ท่าน)'},
		{key: 'CLST',name: 'ห้องคลาสสิค เตียงแฝด (2 ท่าน)' },
		{key: 'CLSTB',name: 'ห้องคลาสสิค เตียงแฝด รวมอาหารเช้า (2 ท่าน)'},
		{key: 'CLSTBD',name: 'ห้องคลาสสิค เตียงแฝด รวมอาหารเช้า และ อาหารเย็น (2 ท่าน)'},
	];

	var roomtypesJP = [
		{key: 'STDT',name: '基準室 ツインベッド　（二人）' },
		{key: 'STDTB',name: '基準室 ツインベッド 朝ご飯付き　（二人）'},
		{key: 'STDTBD',name: '基準室 ツインベッド 朝ご飯、夕食付き（二人）'},
		{key: 'STDD',name: '基準室 ダブルベッド　（二人）'},
		{key: 'STDDB',name: '基準室 ダブルベッド 朝ご飯付き　（二人）'},
		{key: 'STDDBD',name: '基準室 ダブルベッド 朝ご飯、夕食付き　（二人）'},
		{key: 'CLST',name: 'クラシック ツインベッド　（二人）' },
		{key: 'CLSTB',name: 'クラシック ツインベッド 朝ご飯付き　（二人）'},
		{key: 'CLSTBD',name: 'クラシック ツインベッド 朝ご飯、夕食付き（二人）'},
	];

	var roomtypesCN = [
		{key: 'STDT',name: '标准房 两张单人床 （两人）' },
		{key: 'STDTB',name: '标准房 两张单人床 包早餐（两人）'},
		{key: 'STDTBD',name: '标准房 两张单人床  包早餐和晚餐（两人）'},
		{key: 'STDD',name: '标准房 双人床 （两人）'},
		{key: 'STDDB',name: '标准房 双人床 包早餐 （两人）'},
		{key: 'STDDBD',name: '标准房 双人床 包早餐和晚餐（两人）'},
		{key: 'CLST',name: '经典式 两张单人床 （两人）' },
		{key: 'CLSTB',name: '经典式 两张单人床 包早餐（两人）'},
		{key: 'CLSTBD',name: '经典式 两张单人床  包早餐和晚餐（两人）'},
	];

	var roomtypesZN = [
		{key: 'STDT',name: '標準房 兩張單人床 （兩人）' },
		{key: 'STDTB',name: '標準房 兩張單人床 包早餐 （兩人）'},
		{key: 'STDTBD',name: '標準房 兩張單人床 包早餐和晚餐 （兩人）'},
		{key: 'STDD',name: '標準房 雙人床 （兩人）'},
		{key: 'STDDB',name: '標準房 雙人床 包早餐 （兩人）'},
		{key: 'STDDBD',name: '標準房 雙人床 包早餐和晚餐 （兩人）'},
		{key: 'CLST',name: '經典式 兩張單人床 （兩人）' },
		{key: 'CLSTB',name: '經典式 兩張單人床 包早餐 （兩人）'},
		{key: 'CLSTBD',name: '經典式 兩張單人床 包早餐和晚餐 （兩人）'},
	];

	var days = parseInt((new Date(checkoutdate)-new Date(checkindate))/(60*60*24*1000));
	days = days < 1 ? 1 : days;
	var roomprice = rooms * days * parseFloat(roomPrices.filter(type => type.key === roomtypes
												).map(type => type.price)[0]);
	
	var roomTypeString = props.language === 'TH' ? (roomtypesTH.filter(types => types.key === roomtypes).map(types => types.name))[0].toLocaleString()
    						: props.language === 'JP' ? (roomtypesJP.filter(types => types.key === roomtypes).map(types => types.name))[0].toLocaleString()
    						: props.language === 'CN' ? (roomtypesCN.filter(types => types.key === roomtypes).map(types => types.name))[0].toLocaleString()
    						: props.language === 'ZN' ? (roomtypesZN.filter(types => types.key === roomtypes).map(types => types.name))[0].toLocaleString()
    						: (roomtypesEN.filter(types => types.key === roomtypes).map(types => types.name))[0].toLocaleString();
	  
    var checkinString = props.language === 'TH' ? require('dateformat')(new Date(checkindate),'ddddที่ d mmmm ค.ศ. yyyy')
    						: props.language === 'JP' ? require('dateformat')(new Date(checkindate),'yyyy年 mmmm d日 (dddd)')
    						: props.language === 'CN' ? require('dateformat')(new Date(checkindate),'yyyy年 mmmm d号 (dddd)')
    						: props.language === 'ZN' ? require('dateformat')(new Date(checkindate),'yyyy年 mmmm d號 (dddd)')
    						: require('dateformat')(new Date(checkindate),'dddd, mmmm d, yyyy');
	
    var checkoutString = props.language === 'TH' ? require('dateformat')(new Date(checkoutdate),'ddddที่ d mmmm ค.ศ. yyyy')
    						: props.language === 'JP' ? require('dateformat')(new Date(checkoutdate),'yyyy年 mmmm d日 (dddd)')
    						: props.language === 'CN' ? require('dateformat')(new Date(checkoutdate),'yyyy年 mmmm d号 (dddd)')
    						: props.language === 'ZN' ? require('dateformat')(new Date(checkoutdate),'yyyy年 mmmm d號 (dddd)')
    						: require('dateformat')(new Date(checkoutdate),'dddd, mmmm d, yyyy')
	useEffect(()=>{
		if(showModal){
			setShowModal(true);
		}
		if(show && checked){
			const {OmiseCard} = window;
				OmiseCard.configure({
					  publicKey: 'pkey_test_5keii1ocfydoxpq8u6n'
					});

				  // var button = document.querySelector('#checkout-button');
				  // var form = document.querySelector("#checkoutForm");

				  // button.addEventListener("click", (event) => {
				  //   event.preventDefault();
				  //   OmiseCard.open({
				  //     amount: roomprice * 100,
				  //     currency: "THB",
				  //     defaultPaymentMethod: "credit_card",
				  //     image: logo,
				  //     submitLabel: (props.language === 'TH' ? `ชำระ`
			   //  						: props.language === 'JP' ? `支払い`
			   //  						: props.language === 'CN' ? `支付`
			   //  						: props.language === 'ZN' ? `支付`
			   //  						: `Pay`
    		// 					),
				  //     locale: (props.language === 'TH' ? 'th'
			   //  						: props.language === 'JP' ? 'ja'
			   //  						: props.language === 'CN' ? 'zh'
			   //  						: props.language === 'ZN' ? 'zh'
			   //  						: 'en'),
				  //     otherPaymentMethods: "truemoney,promptpay,alipay,bill_payment_tesco_lotus,internet_banking",
				  //     onCreateTokenSuccess: (nonce) => {
				  //         if (nonce.startsWith("tokn_")) {
				  //             form.omiseToken.value = nonce;
				  //             alert(nonce);
				  //         } else {
				  //             form.omiseSource.value = nonce;
				  //             alert(nonce);
				  //         };
				  //       form.submit();
				  //     }
				  //   });
				  // });
		
					OmiseCard.configureButton('#checkout-button', {
					  amount: roomprice * 100,
					  currency: 'THB',
					  submitLabel: (props.language === 'TH' ? `ชำระ`
			    						: props.language === 'JP' ? `支払い`
			    						: props.language === 'CN' ? `支付`
			    						: props.language === 'ZN' ? `支付`
			    						: `Pay`
    							),
					  image: logo,
				      locale: (props.language === 'TH' ? 'th'
			    						: props.language === 'JP' ? 'ja'
			    						: props.language === 'CN' ? 'zh'
			    						: props.language === 'ZN' ? 'zh'
			    						: 'en'),
					  frameLabel: 'Sinkiat Thani Hotel',
					  defaultPaymentMethod: "credit_card",
					  otherPaymentMethods: "truemoney,promptpay,alipay,bill_payment_tesco_lotus,internet_banking"
					});
		
					// // OmiseCard.configureButton('#checkout-button', {
					// //   publicKey: 'OMISE_PUBLIC_KEY',
					// //   amount: 10000,
					  
					// //   submitLabel: 'Pay',
					// // });
		
					// // OmiseCard.configureButton('#checkout-button-alt', {
					// //   amount: 100000,
					// //   currency: 'THB',
					// //   buttonLabel: 'Pay 1000 THB'
					// // });

					OmiseCard.attach();
				}
	},[show,roomprice,props.language,checked,showModal]);

	 const validateForm = () => {

		if(name !== '' && email !== '' && tel !== ''){
			setShow(true);
		}else{
			setShow(false);
		}
	  }	

	 const handleAgreementCheckBox = () => {
	 	if(checked){
	 		setChecked(false);
	 	}else{
	 		setChecked(true);
	 	}
	 	
	 }

	  const clickBack = () => {
	  	setShow(false);
	  	// document.getElementById('formRoomtypes').value = roomtypes;
	  	// document.getElementById('formCheckin').value = checkindate;
	  	// document.getElementById('formCheckout').value = checkoutdate;
	  	// document.getElementById('formName').value = name;
	  	// document.getElementById('formEmail').value = email;
	  	// document.getElementById('formTel').value = tel;
	  	// document.getElementById('formRooms').value = rooms;
	  	// document.getElementById('formRemark').value = remark;
	  	setCheckin(require('dateformat')(tomorrow,'yyyy-mm-dd'));
	  	setCheckout(require('dateformat')(tomorrow,'yyyy-mm-dd'));
	  	setStartDate(moment());
	  	setEndDate(moment());
	  	setName('');
	  	setEmail('');
	  	setTel('');
	  	setRemark('');
	  	setRooms(1);
	  	setRoomtypes('STDT')
	  	setChecked(false);
	  }

	 const setDates = (start,end)=> {
	 	setStartDate(start);
	 	setEndDate(end);
	 	if(start !== null){
	 		
	 		setCheckin(require('dateformat')(new Date(start.toString()),'yyyy-mm-dd'));
	 	}
	 	if(end !== null){
	 		
	 		setCheckout(require('dateformat')(new Date(end.toString()),'yyyy-mm-dd'))
	 	}
	 	
	 }

	const closeModal = () => {
	    setShowModal(false);
	  }

    const createMarkup = () => {
    	var modalContent = '';
    	if(props.language === 'TH'){
    		modalContent = ``
    	}else{
    		modalContent = ``
    	}
    	return {__html: modalContent }
       }

       const showAgreementModal = () => {
       		setShowModal(true);
       		return false;
       }

	return (
		<div className='booknow fade-in'>
		    			{props.langageSwitch()}
		    			<div className='flex justify-start ma3'>
		    				<div className='flex flex-row items-center'>
		    					<div 
									className='bg-black yellow dib br3 pa3 mt2 mb2 bw2 shadow-5 o-80'
									onClick={()=>props.showSection('home')}>
									<img className='o-100 w4' src={props.logo} alt='Sinkiat Thani Hotel'/>
						    	</div>
				    			<div className='flex justify-around'>
					    			{props.contentSwitch()}
				    			</div>
		    				</div>
		    			</div>
		    			<div className='fixed bottom-1 w-100'>
			    			<div className='flex flex-column w-100 h-60 bg-black white o-80 dib tc pa3 mt2 mb2 bw2 shadow-5 '>
			    				<div className='f3 yellow tl'>
			    					{
			    						props.language === 'TH' ? `จองห้องพัก`
			    						: props.language === 'JP' ? `予約する`
			    						: props.language === 'CN' ? `订房`
			    						: props.language === 'ZN' ? `訂房`
			    						: `Make a reservation`
			    					} (TEST)
			    				</div>
				    				{ !show ? <div className='w-100'>
				    								    					<form className='flex flex-row items-center'>

				    								    						<div className='flex flex-column justify-between w-100'>
				    									    						<div className='flex flex-column justify-between w-100'>
				    									    							<label className='tl yellow f5 mt2'>
				    									    								{
				    									    									props.language === 'TH' ? `ชนิดห้องพัก`
																		    						: props.language === 'JP' ? `ルームの種類`
																		    						: props.language === 'CN' ? `房子种类`
																		    						: props.language === 'ZN' ? `房子種類`
																		    						: `Room Types`
					    									    							}
				    									    							</label>
				    									    							<select 
				    									    								className='br3 bg-white b--black pa2' 
				    									    								type='text'
				    									    								id='formRoomtypes'
				    									    								onChange={(e)=>setRoomtypes(e.target.value)} 
				    									    								>
				    									    								{
				    									    									props.language === 'TH' ? roomtypesTH.map(type => {
						    									    									return <option key={type.key} value={type.key}>{type.name} ...{(roomPrices.filter(room => room.key === type.key).map(room=>room.price))[0]}บาท</option>
						    									    								})
																		    						: props.language === 'JP' ? roomtypesJP.map(type => {
						    									    									return <option key={type.key} value={type.key}>{type.name} ...{(roomPrices.filter(room => room.key === type.key).map(room=>room.price))[0]}バーツ</option>
						    									    								})
																		    						: props.language === 'CN' ? roomtypesCN.map(type => {
						    									    									return <option key={type.key} value={type.key}>{type.name} ...{(roomPrices.filter(room => room.key === type.key).map(room=>room.price))[0]}泰铢</option>
						    									    								})
																		    						: props.language === 'ZN' ? roomtypesZN.map(type => {
						    									    									return <option key={type.key} value={type.key}>{type.name} ...{(roomPrices.filter(room => room.key === type.key).map(room=>room.price))[0]}泰銖</option>
						    									    								})
																		    						: roomtypesEN.map(type => {
						    									    									return <option key={type.key} value={type.key}>{type.name} ...{(roomPrices.filter(room => room.key === type.key).map(room=>room.price))[0]}THB</option>
						    									    								})
																		    				}
				    									    							</select>
				    									    						</div>
																				       
				    									    						<div className='flex flex-row justify-between w-100'>
				    										    						<div className='flex flex-column w-100'>
				    										    							<label className='tl yellow f5 mt2'>
				    										    								{
				    									    									props.language === 'TH' ? `ช่วงเวลาเข้าพัก`
																		    						: props.language === 'JP' ? `チェクイン & チェクアウト`
																		    						: props.language === 'CN' ? `入住和退房`
																		    						: props.language === 'ZN' ? `入住和退房`
																		    						: `Check-In & Check-Out`
					    									    							}
				    										    							</label>
				    										    							 <DateRangePicker
																							  startDate={startdate} // momentPropTypes.momentObj or null,
																							  startDateId="STARTDATEID" // PropTypes.string.isRequired,
																							  endDate={enddate} // momentPropTypes.momentObj or null,
																							  endDateId="ENDDATEID" // PropTypes.string.isRequired,
																							  onDatesChange={({ startDate, endDate }) => setDates(startDate,endDate)} // PropTypes.func.isRequired,
																							  focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
																							  onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
																							  block={true}
																							/>
				    										    						</div>
				    										    					</div>
				    										    					<div className='flex flex-row justify-between w-100'>
				    											    					<div className='flex flex-column w-50'>
				    											    						<label className='tl yellow f5 mt2'>
				    											    						{
				    									    									props.language === 'TH' ? `ชื่อสกุล`
																		    						: props.language === 'JP' ? `氏名`
																		    						: props.language === 'CN' ? `姓名`
																		    						: props.language === 'ZN' ? `姓名`
																		    						: `Full Name`
					    									    							}</label>
				    											    						<input 
				    											    							className='br3 bg-white b--black pa2'  
				    											    							id='formName'
				    											    							type='text' 
				    											    							placeholder={
					    									    									props.language === 'TH' ? `กรุณาใส่ชื่อ-สกุล`
																			    						: props.language === 'JP' ? `お名前は？`
																			    						: props.language === 'CN' ? `名字叫什么？`
																			    						: props.language === 'ZN' ? `名字叫什麼？`
																			    						: `What's your name?`
						    									    							}
				    											    							autoComplete="off"
				    											    							onChange={(e)=>setName(e.target.value)}
				    											    							required/>
				    											    					</div>
				    											    					<div className='flex flex-column w-50'>
				    											    						<label className='tl yellow f5 mt2'>{
				    									    									props.language === 'TH' ? `อีเมล`
																		    						: props.language === 'JP' ? `Eメール`
																		    						: props.language === 'CN' ? `电子邮件`
																		    						: props.language === 'ZN' ? `电子郵件`
																		    						: `Email`
					    									    							}</label>
				    											    						<input 
				    											    							className='br3 bg-white b--black pa2'  
				    											    							id='formEmail'
				    											    							type='email' 
				    											    							placeholder='example@email.com'
				    											    							autoComplete="off"
				    											    							onChange={(e)=>setEmail(e.target.value)}
				    											    							required/>
				    											    					</div>
				    										    					</div>
				    										    					<div className='flex flex-row justify-between w-100'>
				    										    						<div className='flex flex-column w-50'>
				    											    						<label className='tl yellow f5 mt2'>{
				    									    									props.language === 'TH' ? `เบอร์โทรติดต่อ`
																		    						: props.language === 'JP' ? `携帯電話番号`
																		    						: props.language === 'CN' ? `手机号码`
																		    						: props.language === 'ZN' ? `手機號碼`
																		    						: `Tel.`
					    									    							}</label>
				    											    						<input 
				    											    							className='br3 bg-white b--black pa2'  
				    											    							id='formTel'
				    											    							type='tel' 
				    											    							placeholder='+66 074 711 336'
				    											    							autoComplete="off"
				    											    							onChange={(e)=>setTel(e.target.value)}
				    											    							required/>
				    									    							</div>
				    											    					<div className='flex flex-column w-50'>
				    											    						<label className='tl yellow f5 mt2'>{
				    									    									props.language === 'TH' ? `จำนวนห้องพัก`
																		    						: props.language === 'JP' ? `部屋数`
																		    						: props.language === 'CN' ? `房间数量`
																		    						: props.language === 'ZN' ? `房間數量`
																		    						: `Number of rooms`
					    									    							}
					    									    							</label>
				    											    						<input 
				    											    							className='br3 bg-white b--black pa2'  
				    											    							id='formRooms'
				    											    							type='number' 
				    											    							min='1'
				    											    							defaultValue='1'
				    											    							autoComplete="off"
				    											    							onChange={(e)=>setRooms(e.target.value)}
				    											    							onKeyDown={(e)=>e.preventDefault()}
				    											    							placeholder={
					    									    									props.language === 'TH' ? `ต้องการกี่ห้องพัก`
																			    						: props.language === 'JP' ? `部屋のいくつ？`
																			    						: props.language === 'CN' ? `多少房间？`
																			    						: props.language === 'ZN' ? `多少房間？`
																			    						: `How many rooms?`
						    									    							}
						    									    							/>
				    											    					</div>
				    									    						</div>
				    									    						<div className='flex flex-row justify-between w-100'>
				    										    						<div className='flex flex-column w-100'>
				    											    						<label className='tl yellow f5 mt2'>
				    											    							{
					    									    									props.language === 'TH' ? `หมายเหตุ`
																			    						: props.language === 'JP' ? `スペシャルニーズ`
																			    						: props.language === 'CN' ? `特殊要求`
																			    						: props.language === 'ZN' ? `特殊要求`
																			    						: `Remark`
						    									    							}
				    											    						</label>
				    											    						<input 
				    											    							className='br3 bg-white b--black pa2'  
				    											    							id='formRemark'
				    											    							type='text' 
				    											    							placeholder={
					    									    									props.language === 'TH' ? `คำร้องขอพิเศษ`
																			    						: props.language === 'JP' ? `特別なリクエスト？`
																			    						: props.language === 'CN' ? `特殊要求？`
																			    						: props.language === 'ZN' ? `特殊要求？`
																			    						: `Any preferences?`
						    									    							}
				    											    							autoComplete="off"
				    											    							onChange={(e)=>setRemark(e.target.value)}/>
				    									    							</div>
				    									    						</div>
				    									    						<div>
				    									    							<button className='black bg-yellow b--yellow br3 shadow-5 mt1' type='submit' onClick={()=>validateForm()} >{
					    									    									props.language === 'TH' ? `จองห้องพัก`
																			    						: props.language === 'JP' ? `予約`
																			    						: props.language === 'CN' ? `订房`
																			    						: props.language === 'ZN' ? `訂房`
																			    						: `Book Now`
						    									    							}</button>
						    									    					<button className='br3 b--white bg-white ml2' type ='reset' onClick={()=>clickBack()}>{
					    									    									props.language === 'TH' ? `เริ่มใหม่`
																			    						: props.language === 'JP' ? `やり直す`
																			    						: props.language === 'CN' ? `从新开始`
																			    						: props.language === 'ZN' ? `從新開始`
																			    						: `Reset`
						    									    							}</button>
				    									    						</div>
				    									    					</div>
				    								    					</form>
				    								    				</div> 
				    								    				: 
				    								<div className='w-100'>
				    									<div className='ba b--yellow br3 ma1'>
				    										<div className='tl f5 yellow ml3'>{
					    									    									props.language === 'TH' ? `ข้อมูลการจอง`
																			    						: props.language === 'JP' ? `予約情報`
																			    						: props.language === 'CN' ? `订房简介`
																			    						: props.language === 'ZN' ? `訂房簡介`
																			    						: `Booking Information`
						    									    							}</div>
				    										<div >{
				    									    									props.language === 'TH' ? `เช็คอิน`
																		    						: props.language === 'JP' ? `チェクイン`
																		    						: props.language === 'CN' ? `入住`
																		    						: props.language === 'ZN' ? `入住`
																		    						: `Check In`
					    									    							}: {checkinString}</div>
				    										<div>{
				    									    									props.language === 'TH' ? `เช็คเอ้าท์`
																		    						: props.language === 'JP' ? `チェクアウト`
																		    						: props.language === 'CN' ? `退房`
																		    						: props.language === 'ZN' ? `退房`
																		    						: `Check Out`
					    									    							}: {checkoutString}</div>
				    										<div>{
				    									    									props.language === 'TH' ? `ชนิดห้องพัก`
																		    						: props.language === 'JP' ? `ルームの種類`
																		    						: props.language === 'CN' ? `房子种类`
																		    						: props.language === 'ZN' ? `房子種類`
																		    						: `Room Types`
					    									    							}: {roomTypeString}</div>
				    										<div>{
				    									    									props.language === 'TH' ? `จำนวน`
																		    						: props.language === 'JP' ? `数`
																		    						: props.language === 'CN' ? `数量`
																		    						: props.language === 'ZN' ? `數量`
																		    						: `Rooms/Days`
					    									    							}: {rooms} {props.language === 'TH' ? `ห้อง`
																		    						: props.language === 'JP' ? `部屋`
																		    						: props.language === 'CN' ? `房间`
																		    						: props.language === 'ZN' ? `房間`
																		    						: `Room(s)`}, {days} {props.language === 'TH' ? `คืน`
																		    						: props.language === 'JP' ? `泊`
																		    						: props.language === 'CN' ? `晚`
																		    						: props.language === 'ZN' ? `晚`
																		    						: `Night(s)`}</div>
				    									</div>
				    									<div className='ba b--yellow br3 ma1'>
				    										<div className='tl f5 yellow ml3'>{
					    									    									props.language === 'TH' ? `ข้อมูลผู้เข้าพัก`
																			    						: props.language === 'JP' ? `個人情報`
																			    						: props.language === 'CN' ? `个人信息`
																			    						: props.language === 'ZN' ? `個人信息`
																			    						: `Personal Information`
						    									    							}</div>
					    									<div>{
				    									    									props.language === 'TH' ? `ชื่อสกุล`
																		    						: props.language === 'JP' ? `氏名`
																		    						: props.language === 'CN' ? `姓名`
																		    						: props.language === 'ZN' ? `姓名`
																		    						: `Full Name`
					    									    							}: {name}</div>
					    									<div>{
				    									    									props.language === 'TH' ? `อีเมล`
																		    						: props.language === 'JP' ? `Eメール`
																		    						: props.language === 'CN' ? `电子邮件`
																		    						: props.language === 'ZN' ? `电子郵件`
																		    						: `Email`
					    									    							}: {email}</div>
					    									<div>{
				    									    									props.language === 'TH' ? `เบอร์โทรติดต่อ`
																		    						: props.language === 'JP' ? `携帯電話番号`
																		    						: props.language === 'CN' ? `手机号码`
																		    						: props.language === 'ZN' ? `手機號碼`
																		    						: `Tel.`
					    									    							}: {tel}</div>
					    									<div>{
					    									    									props.language === 'TH' ? `หมายเหตุ`
																			    						: props.language === 'JP' ? `スペシャルニーズ`
																			    						: props.language === 'CN' ? `特殊要求`
																			    						: props.language === 'ZN' ? `特殊要求`
																			    						: `Remark`
						    									    							}: {remark}</div>
					    								</div>
					    								<form id="checkoutForm" method="POST" action="https://www.sinkiathotel.com/payment/checkout" >
						    								<input type="hidden" name="guestName" value={name} />
															<input type="hidden" name="guestEmail" value={email} />
  															<input type="hidden" name="guestTel" value={tel} />
  															<input type="hidden" name="guestRemark" value={remark} />
															<input type="hidden" name="guestRooms" value={rooms} />
  															<input type="hidden" name="guestCheckin" value={checkinString} />
  															<input type="hidden" name="guestCheckout" value={checkoutString} />
															<input type="hidden" name="guestRoomType" value={roomtypes} />
															<input type="hidden" name="guestRoomTypeString" value={roomTypeString} />
															<input type="hidden" name="guestTotal" value={roomprice} />
															<input type="hidden" name="guestDays" value={days} />
															<input type="hidden" name="lang" value={props.language} />
					    									<div className='yellow f3'>{
					    									    									props.language === 'TH' ? `รวมเงิน`
																			    						: props.language === 'JP' ? `全額`
																			    						: props.language === 'CN' ? `总额`
																			    						: props.language === 'ZN' ? `總額`
																			    						: `Total`
						    									    							} {roomprice} {
					    									    									props.language === 'TH' ? `บาท`
																			    						: props.language === 'JP' ? `バーツ`
																			    						: props.language === 'CN' ? `泰铢`
																			    						: props.language === 'ZN' ? `泰銖`
																			    						: `THB`
						    									    							}</div>
						    								<div className='flex flex-row'>
						    									<input className='ma2' type='checkbox' id='agreementBox' value='agree' onClick={()=>handleAgreementCheckBox()} required />
						    									{
						    										props.language === 'TH' ? <label id='agreementLabel'>ข้าพเจ้าได้อ่านและยอมรับ<button className='bg-transparent b--transparent white underline' type='button' onClick={()=>showAgreementModal()}>ข้อตกลงการใช้งาน</button></label>
										    						: props.language === 'JP' ? <label id='agreementLabel'><button className='bg-transparent b--transparent white underline' type='button' onClick={()=>showAgreementModal()}>利用規約</button>に読み、同意する</label>
										    						: props.language === 'CN' ? <label id='agreementLabel'>我已认真阅读并同意<button className='bg-transparent b--transparent white underline' type='button' onClick={()=>showAgreementModal()}>用户条款</button></label>
										    						: props.language === 'ZN' ? <label id='agreementLabel'>我已認真閱讀並同意<button className='bg-transparent b--transparent white underline' type='button' onClick={()=>showAgreementModal()}>用戶條款</button></label>
										    						: <label id='agreementLabel'>I've read and agree to <button className='bg-transparent b--transparent white underline' type='button' onClick={()=>showAgreementModal()}>terms & condition</button></label>
										    					}
						    								</div>
					                   						<input className='br3 b--yellow bg-yellow black ml2' type='submit' id='checkout-button' value={
					                   							props.language === 'TH' ? `ชำระเงิน`
																			    						: props.language === 'JP' ? `支払い`
																			    						: props.language === 'CN' ? `付款`
																			    						: props.language === 'ZN' ? `付款`
																			    						: `Pay Now`
					                   						}/>
					                   						<button className='br3 b--white bg-white ml2' type ='reset' onClick={()=>clickBack()}>{
					    									    									props.language === 'TH' ? `เริ่มใหม่`
																			    						: props.language === 'JP' ? `やり直す`
																			    						: props.language === 'CN' ? `从新开始`
																			    						: props.language === 'ZN' ? `從新開始`
																			    						: `Reset`
						    									    							}</button>
					                   					</form>
				    								</div>
				    				}
				    		</div>
				    		<BooknowModal 
				                  show={showModal} 
				                  handleClose={()=>closeModal()} 
				                  language={props.language}
				                  >
				                    <div dangerouslySetInnerHTML={createMarkup()} />
				            </BooknowModal>
			    		</div>
				    </div>
	);
}

export default Booknow;