import React from 'react';
import Grid from 'react-grid-gallery';


function Attraction(props) {
	const IMAGES =
		[{
		        src: 'https://bkkaruncloud.b-cdn.net/wp-content/uploads/2019/05/hat-yai-to-koh-lipe.jpg',
		        thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS9jNDKLcN9jdwK62EIlBaKvD20Tkyi0jmPl3NTdshw-YUcCYqJ',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "เกาะหลีเป๊ะ" 
			        : props.language === 'JP' ? "リペ島" 
			        : props.language === 'CN' ? "丽贝岛" 
			        : props.language === 'ZN' ? "麗貝島" 
		        	: "Lipe Island"
		},
		{
		        src: 'https://paapaii.com/wp-content/uploads/2018/05/%E0%B9%80%E0%B8%81%E0%B8%B2%E0%B8%B0%E0%B9%84%E0%B8%82%E0%B9%882.jpg',
		        thumbnail: 'https://paapaii.com/wp-content/uploads/2018/05/%E0%B9%80%E0%B8%81%E0%B8%B2%E0%B8%B0%E0%B9%84%E0%B8%82%E0%B9%882.jpg',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "เกาะไข่" 
		        	: "Egg Island"
		},
		{
		        src: 'https://www.isranews.org/images/2018/thaireform/04/SATUN2140461.jpg',
		        thumbnail: 'https://www.isranews.org/images/2018/thaireform/04/SATUN2140461.jpg',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ถ้ำเลสเตโกดอน" 
		        	: "Fossil Cave"
		},
		{
		        src: 'https://f.tpkcdn.com/review-source/ea1c6aad-bc38-e55e-7212-57428efc5a19.jpg',
		        thumbnail: 'https://f.tpkcdn.com/review-source/ea1c6aad-bc38-e55e-7212-57428efc5a19.jpg',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ถ้ำภูผาเพชร" 
		        	: "Phu Pha Phet Cave"
		},
		{
		        src: 'https://tourismproduct.tourismthailand.org/uploads/201607/20160702163738_0.jpg',
		        thumbnail: 'https://sites.google.com/site/it125565520117084/_/rsrc/1380008958478/stul/wangsaytong2day.jpg?height=266&width=400',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "น้ำตกวังสายทอง" 
		        	: "Wang Sai Thong Waterfall"
		},
		{
		        src: 'https://www.thai-tour.com/include/img_b/tt5a14f9c937b8f.jpg',
		        thumbnail: 'https://www.thai-tour.com/include/img_b/tt5a14f9c937b8f.jpg',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ท่าเรือตำมะลัง" 
		        	: "Tam Ma Lang Pier"
		},
		{
		        src: 'https://img.kapook.com/u/2017/pattra/pat%20Feb,%202017/beach03.jpg',
		        thumbnail: 'https://img.kapook.com/u/2017/pattra/pat%20Feb,%202017/beach03.jpg',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "สันหลังมังกรที่ตันหยงโป" 
		        	: "San Lang Mangkorn Tan Yong Po"
		},
		{
		        src: 'https://s.isanook.com/tr/0/rp/r/w728/ya0xa0m1w0/aHR0cHM6Ly9zLmlzYW5vb2suY29tL3RyLzAvdWQvMjgyLzE0MTE5Nzkvb2JmNzdmZDdjMjBkOGM4MDhjZjQ1MTEyNTJlZjBfMTQuanBn.jpg',
		        thumbnail: 'https://s.isanook.com/tr/0/rp/r/w728/ya0xa0m1w0/aHR0cHM6Ly9zLmlzYW5vb2suY29tL3RyLzAvdWQvMjgyLzE0MTE5Nzkvb2JmNzdmZDdjMjBkOGM4MDhjZjQ1MTEyNTJlZjBfMTQuanBn.jpg',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ปราสาทหินพันยอด" 
		        	: "Pra Sat Hin Pan Yod"
		},
		{
		        src: 'https://www.greenviewkohlipe.com/htm/img/activities/diving1.jpg',
		        thumbnail: 'https://www.greenviewkohlipe.com/htm/img/activities/diving1.jpg',
		        thumbnailWidth: 80,
		        thumbnailHeight: 80,
		        caption: props.language === 'TH' ? "ดำน้ำลึกที่อุทยานแห่งชาติตะรุเตา" 
			        : props.language === 'JP' ? "タルタオでスクバダイビング" 
			        : props.language === 'CN' ? "在达鲁国家海洋公园潜水" 
			        : props.language === 'ZN' ? "在達魯國家海洋公園潛水" 
		        	: "Scuba Diving in Tarutao National Marine Park"
		}]
	return (
		<div className='attraction fade-in'>
		    			{props.langageSwitch()}
		    			<div className='flex justify-start ma3'>
		    				<div className='flex flex-row items-center'>
		    					<div 
									className='bg-black yellow dib br3 pa3 mt2 mb2 bw2 shadow-5 o-80'
									onClick={()=>props.showSection('home')}>
									<img className='o-100 w4' src={props.logo} alt='Sinkiat Thani Hotel'/>
						    	</div>
				    			<div className='flex justify-around'>
					    			{props.contentSwitch()}
				    			</div>
		    				</div>
		    			</div>
		    			<div className='fixed bottom-1 w-100'>
			    			<div className='w-100 h-60 bg-black white o-80 dib tc pa3 mt2 mb2 bw2 shadow-5 '>
		    					<div className='f3 yellow tl'>
			    					{props.language === 'TH' ? 'จุดท่องเที่ยว' : props.language === 'CN' ? '景点' : props.language === 'ZN' ? '景點' : props.language === 'JP' ? 'アトラクション' : 'Attraction'}
		    					</div>
		    					<div>	
				    				<Grid 
				    					images={IMAGES}
				    					rowHeight='80'
				    					maxRow='1'
				    					enableImageSelection='false'
				    				/>
								</div>
				    		</div>
				    	</div>
				    </div>
	);
}

export default Attraction;