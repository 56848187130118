import React from 'react';

function Room(props) {
	return (
		<div className='room fade-in'>
		    			{props.langageSwitch()}
		    			<div className='flex justify-start ma3'>
		    				<div className='flex flex-row items-center'>
		    					<div 
									className='bg-black yellow dib br3 pa3 mt2 mb2 bw2 shadow-5 o-80'
									onClick={()=>props.showSection('home')}>
									<img className='o-100 w4' src={props.logo} alt='Sinkiat Thani Hotel'/>
						    	</div>
				    			<div className='flex justify-around'>
					    			{props.contentSwitch()}
				    			</div>
		    				</div>
		    			</div>
		    			<div className='fixed bottom-1 w-100'>
			    			<div className='w-100 h-60 bg-black white o-80 dib tc pa3 mt2 mb2 bw2 shadow-5 '>
			    				<div className='f3 yellow tl'>
			    					{
			    						props.language === 'TH' ? `ห้องมาตรฐาน`
			    						: props.language === 'JP' ? `基準室`
			    						: props.language === 'CN' ? `标准房`
			    						: props.language === 'ZN' ? `標準房`
			    						: `Standard Room`
			    					}
			    				</div>
				    				{
				    					props.language === 'TH' ? 
				    					<div>
					    					<div className='tl'>
					    					ห้องพักชั้นมาตรฐาน สะดวกสบาย 
					    					ตัวอาคารติดถนนหลักเมืองเก่า ภายในห้องพัก สามารถเลือกชมวิวของเมืองเก่า หรือวิวทิวเขาและแม่น้ำได้ 
					    					ห้องน้ำในตัว มีน้ำอุ่น มี wi-fi เครื่องปรับอากาศ และโทรทัศน์ทุกห้อง
					    					ราคาห้องพัก เตียงเดี่ยว/คู่ เริ่มต้นที่ 680 บาท ถึง 1,100 บาท/คืน ต้องการเตียงเสริมเพิ่มเตียงละ 200.-
					    					ต้องการอาหารเช้าเพิ่มท่านละ 100.-
					    					</div>
				    						<div className='tl'>**เราบริการฟรีเตียงเสริม สำหรับเด็ก 1 คนอายุไม่เกิน 12 ปีเข้าพักร่วมกับผู้ใหญ่**</div>
				    						<div className='tl'>เวลาเช็คอิน : ตั้งแต่ 14.00 น.</div>
				    						<div className='tl'>เวลาเช็คเอาท์ : ก่อน 11.00 น.</div>
				    						<div className='tl'>รองรับบัตรเครดิต</div>
				    						<div className='tl'>ห้องพักทุกห้องรวมค่าบริการ 10% และค่าภาษี 7%แล้ว</div>
				    					</div>
				    					: props.language === 'JP' ? 
				    					<div>
					    					<div className='tl'>
					    					二種類の眺めを提供致す。「河原の眺め」　と　「都市の眺め」である。
					    					部屋はバスタブ付き、ダブルベットまたはツインベッドを選べる。
					    					部屋の中にテレビ、冷蔵庫、風呂、シャワーなどが設備する。ホテル全体でのWIFIアクセス。
					    					宿泊は６８０バーツからである。追加ベッドは一つ２００バーツで、朝ごはんは一人１００バーつである。
				    						</div>
				    						<div className='tl'>**無料追加ベッド一つ　（１２歳未満子供、家族と宿泊祭） **</div>
				    						<div className='tl'>チェックイン : 午後１２時から</div>
				    						<div className='tl'>チェックアウト : 午前１１時前</div>
				    						<div className='tl'>クレジットカード使用可能。</div>
				    						<div className='tl'>価格は１０％サービズチャージと７％税金含む</div>
				    					</div>
			    						: props.language === 'CN' ? 
			    						<div>
					    					<div className='tl'>
					    					两种方式可选， 两张单人床 或者 双人床。
					    					从客房大窗户可看到 山河风景 或者 市内风景。
					    					每个房间装备电视，冰箱，浴缸和淋浴等。免费无线上网。
					    					住宿费从680泰铢开始。 加上-张床需货200泰铢， 加上早餐需货100泰铢一人。
				    						</div>
				    						<div className='tl'>**免费加-张床（12岁以下小孩，入住时跟家人）**</div>
				    						<div className='tl'>注册 : 从下午2点</div>
				    						<div className='tl'>退房 : 上午11点前</div>
				    						<div className='tl'>信用卡接受</div>
				    						<div className='tl'>价格已包括10%服务费和7%税</div>
				    					</div>
			    						: props.language === 'ZN' ? 
			    						<div>
					    					<div className='tl'>
					    					兩種方式可選， 兩張單人床 或者 雙人床。
					    					從房子裡可看到 山河風景 或者 是哪風景。
					    					每個房間裝備電視，冰箱，浴缸和淋浴等。免費無線上網。
					    					住宿費從680泰銖開始。 加上-張床，需要200泰銖。 加上午餐需要100泰銖一人。
				    						</div>
				    						<div className='tl'>**免費加一張床 （12歲以下小孩兒，入住時跟家人）**</div>
				    						<div className='tl'>入住 : 從下午2點</div>
				    						<div className='tl'>退房 : 上午11點前</div>
				    						<div className='tl'>信用卡接受</div>
				    						<div className='tl'>價格已包括10%服務費和7%稅</div>
				    					</div>
				    					: <div>
					    					<div className='tl'>
					    					Our standard economic room is comfort and affordable. 
				    						Offering river and city views, 
				    						room has double bed or twin bed and bathroom with bathtub and shower.
				    						All room are equiped with TV, refridgerator, and internet access. 
				    						Room rate (Double/Twin) starts at 680 - 1,100.- Baht/night.For Extension Bed add 200.-/bed. For breakfast add 100.-/person.
				    						</div>
				    						<div className='tl'>**Free bed extension for 1 child (Age below 12 year old, when checkin with adult)**</div>
				    						<div className='tl'>Check-in : from 2pm</div>
				    						<div className='tl'>Check-out : before 11am</div>
				    						<div className='tl'>Credit Card Acceptable</div>
				    						<div className='tl'>All rooms inclusive of 10% service charge and 7% TAX</div>
				    					</div>
				    				}
				    				{
					    				props.language === 'TH' ? 
					    					<div className='flex flex-row justify-end'>
					    						<div>
							    					<div className='f6 yellow tr '>ตั้งแต่</div>
							    					<div className='f3 v-mid yellow'>680 บาท</div>
						    					</div>
						    					<button className='f4 no-underline bg-yellow black grow dib tc dib br3 pa2 ma2 bw2 shadow-5 b--black' onClick={()=>props.showSection('booknow')}><b>จองห้องพัก</b></button>
					    					</div>
					    				: props.language === 'JP' ? 
					    					<div className='flex flex-row items-end justify-end'>
					    						<div className='f3 yellow'>680バーツ</div>
						    					<div className='f6 yellow tr '>から</div>
						    					<button className='f4 no-underline bg-yellow black grow dib tc dib br3 pa2 ma2 bw2 shadow-5 b--black' onClick={()=>props.showSection('booknow')}><b>要約する</b></button>
					    					</div>
			    						: props.language === 'CN' ? 
			    							<div className='flex flex-row justify-end'>
						    					<div className='f6 yellow tr '>从</div>
						    					<div className='f3 yellow'>680泰铢</div>
						    					<button className='f4 no-underline bg-yellow black grow dib tc dib br3 pa2 ma2 bw2 shadow-5 b--black' onClick={()=>props.showSection('booknow')}><b>订房</b></button>
					    					</div>
			    						: props.language === 'ZN' ? 
			    							<div className='flex flex-row justify-end'>
						    					<div className='f6 yellow tr '>從</div>
						    					<div className='f3 yellow'>680泰銖</div>
						    					<button className='f4 no-underline bg-yellow black grow dib tc dib br3 pa2 ma2 bw2 shadow-5 b--black' onClick={()=>props.showSection('booknow')}><b>訂房</b></button>
					    					</div>
					    					: <div className='flex flex-row justify-end'>
					    						<div>
							    					<div className='f6 yellow tr '>from</div>
							    					<div className='f3 yellow'>THB 680</div>
						    					</div>
						    					<button className='f4 no-underline bg-yellow black grow dib tc dib br3 pa2 ma2 bw2 shadow-5 b--black' onClick={()=>props.showSection('booknow')}><b>Book Now</b></button>
					    					</div>
					    			}
				    		</div>
			    		</div>
				    </div>
	);
}

export default Room;