import React from 'react';

function Contact(props) {
	const {language} = props;
	return (
		<div className='contact fade-in'>
		    			{props.langageSwitch()}
		    			<div className='flex justify-start ma3'>
		    				<div className='flex flex-row items-center'>
		    					<div 
									className='bg-black yellow dib br3 pa3 mt2 mb2 bw2 shadow-5 o-80'
									onClick={()=>props.showSection('home')}>
									<img className='o-100 w4' src={props.logo} alt='Sinkiat Thani Hotel'/>
						    	</div>
				    			<div className='flex justify-around'>
					    			{props.contentSwitch()}
				    			</div>
		    				</div>
		    			</div>
		    			<div className='fixed bottom-1 w-100'>
			    			<div className='w-100 h-60 bg-black white o-80 dib tc pa3 mt2 mb2 bw2 shadow-5 '>
			    			<div className='f3 yellow tl'>
			    			{
		    					language === 'TH' ? 'ติดต่อ' : language === 'CN' ? '联系' : language === 'ZN' ? '聯繫' : language === 'JP' ? '連絡' : 'Contact'
				    		}
				    		</div>
			    			<div dangerouslySetInnerHTML={ {__html:  `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.2687232274416!2d100.06278581466432!3d6.613496995217455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x304c51eaaaaaaaab%3A0xca9f5cc2bb315bb1!2sSinkiat%20Thani%20Hotel!5e0!3m2!1sen!2sth!4v1582955636219!5m2!1sen!2sth" width="80%" height="50%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>`}} />
				    		{
				    			language === 'TH' ? 
				    			  <div>
				    				<div className='f3 yellow tc'>โรงแรมสินเกียรติธานี</div>
				    				<div>50 ถ.บุรีวานิช ต.พิมาน อ.เมือง จ.สตูล 91000</div>
				    				<div>โทร: 074 711 336</div>
				    				<div>มือถือ/ไลน์: 091 847 1057</div>
				    				<div>อีเมล: <a href='mailto:contact@sinkiathotel.com'>contact@sinkiathotel.com</a></div>
				    			  </div>
				    			: language === 'ZN' ? <div>
				    				<div className='f3 yellow tc'>新建興特尼酒店</div>
				    				<div>50 Buriwanich Rd., Phiman, Mueang, Satun, 91000 Thailand</div>
				    				<div>電話號碼: +66 074 711 336</div>
				    				<div>手機號碼: +66 91 847 1057</div>
				    				<div>電子郵件: <a href='mailto:contact@sinkiathotel.com'>contact@sinkiathotel.com</a></div>
				    			  </div>
				    			: language === 'CN' ? <div>
				    				<div className='f3 yellow tc'>新建兴特尼酒店</div>
				    				<div>50 Buriwanich Rd., Phiman, Mueang, Satun, 91000 Thailand</div>
				    				<div>电话号码: +66 074 711 336</div>
				    				<div>手机号码: +66 91 847 1057</div>
				    				<div>电子邮件: <a href='mailto:contact@sinkiathotel.com'>contact@sinkiathotel.com</a></div>
				    			  </div>
				    			: language === 'JP' ? 
				    			  <div>
				    				<div className='f3 yellow tc'>シンキァッターニホテル</div>
				    				<div>タイ、９１０００、サトゥン府、ピマン、ムァンサトゥン、サトゥンターニ、９/１１ー１２</div>
				    				<div>電話番号: +66 074 711 336</div>
				    				<div>携帯電話・ライン: +66 91 847 1057</div>
				    				<div>メール: <a href='mailto:contact@sinkiathotel.com'>contact@sinkiathotel.com</a></div>
				    			  </div>
				    			: <div>
				    				<div className='f3 yellow tc'>Sinkiat Thani Hotel</div>
				    				<div>50 Buriwanich Rd., Phiman, Mueang, Satun, 91000 Thailand</div>
				    				<div>Tel: +66 074 711 336</div>
				    				<div>Mobile: +66 91 847 1057</div>
				    				<div>Email: <a href='mailto:contact@sinkiathotel.com'>contact@sinkiathotel.com</a></div>
				    			  </div>
				    		}
				    		</div>
			    		</div>
				    </div>
	);
}

export default Contact;