import React, {useRef} from 'react';
import './Modal.css';
import useOutsideClick from "./useOutsideClick";

function Modal(props){

  const { show, children, handleClose, onSubmit, buttonText} = props;
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const ref = useRef();

  useOutsideClick(ref, () => {
      handleClose();
   });

  	return (
  		<div className={showHideClassName}>
	      <section className="modal-main black">
	      	<button className='no-underline ba b--transparent f7 fw1 i fixed top-1 right-1 bg-transparent flex-left gray' id='closeButtonTop' onClick={handleClose}>CLOSE</button>
	        <div ref={ref}>
	        	{children}
	        </div>
	        <button className='flex-start br3 pa2 ma3 bg-yellow b--yellow' id='submitButton' onClick={onSubmit}>{buttonText}</button>
	        <button className={`flex-end br3 pa2 bg-gray b--gray dn`} id='closeButtonBtm' onClick={handleClose}>ปิด ｜ CLOSE</button>
	      </section>
	    </div>
	    );	
 }

export default Modal;