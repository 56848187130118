import React, {useRef} from 'react';
import './Booknow.css';
import useOutsideClick from "../useOutsideClick";

function BooknowModal(props){

  const { show, handleClose} = props;
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const ref = useRef();

  useOutsideClick(ref, () => {
      handleClose();
   });



  	return (
  		<div className={showHideClassName}>
	      <section className="modal-main black">
	      	<button className='no-underline ba b--transparent f7 fw1 i fixed top-1 right-1 bg-transparent flex-left gray' id='closeButtonTop' onClick={handleClose}>CLOSE</button>
	        <div ref={ref}>
	        	{
	        		props.language === 'TH' ? 
	        			<div className='f3 flex flex-column'>
    							<div><b>นโยบายการคุ้มครองข้อมูล</b></div>
    							<div className='f5 ba br3 b--black black tl pa2'>
    								<p><b>นโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัท สินเกียรติธานี จำกัด</b></p>
    								<p><b>ข้อมูลเบื้องต้น</b></p>
    								<p>เราจะทำการเก็บข้อมูลเมื่อคุณเข้าชมเว็บไซต์ของเรา รวมถึงการใช้บริการต่างๆ ตามที่ปรากฏในหน้าเว็บไซต์ เช่น เมื่อมีการทำการนัดหมาย การขอข้อมูล การสำรองห้องพัก การสั่งซื้อสินค้าและบริการ การโพสต์รูปภาพ การส่งข้อคิดเห็น สำหรับการสมัครเพื่อเป็นสมาชิกหรือการใช้บริการต่างๆ อาจมีการขอข้อมูลเพิ่มเติม</p>
    								<p><b>ขอบเขตของนโยบายคุ้มครองข้อมูลส่วนบุคคล</b></p>
    								<p>นโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ใช้ภายใต้เว็บไซต์ www.sinkiathotel.com รวมถึงบริการอื่นๆ ซึ่งสามารถเข้าถึงได้ภายในแพลทฟอร์มในเว็บไซต์นี้ ทั้งนี้อาจมีการแสดงข้อกำหนดซึ่งเกี่ยวข้องกับนโยบายคุ้มครองข้อมูลส่วนบุคคลเพิ่มเติมในบริการ เพื่อให้คุณอนุมัติก่อนการเข้าถึงบริการนั้นๆ</p>
    								<p><b>หมายเหตุ</b>: นโยบายส่วนบุคคลนี้จะไม่ใช้กับข้อมูลซึ่งจะได้เปิดเผยหรือทำการส่งไปยัง</p>
    								<p>- บริการอื่นๆนอกเหนือจากที่กำหนดในเว็บไซต์ของเรา เช่น สินค้าหรือบริการอื่นๆซึ่งดำเนินการโดยเราหรือบริษัทในเครือ ซึ่งไม่ได้ระบุโดยชัดแจ้งว่าอยู่ภายใต้บังคับของนโยบายนี้</p>
    								<p>- บริการของบุคคลที่สาม รวมถึงเว็บไซต์ของบุคคลที่สาม ซึ่งคุณอาจเข้าถึงโดยผ่านทางเว็บไซต์ของเรา</p>
    								<p>- บริษัท หรือองค์กร ซึ่งได้ทำการโฆษณาสินค้าหรือบริการ ในเว็บไซต์ของเรา</p>
    								<p><b>ข้อจำกัด</b></p>
    								<p>เว็บไซต์ของเราไม่มุ่งหมายเพื่อการใช้งานสำหรับเด็กอายุต่ำกว่า 16 ปี</p>
    								<p><b>การปรับปรุงนโยบายคุ้มครองข้อมูลส่วนบุคคล</b></p>
    								<p>การปรับปรุงนโยบายคุ้มครองข้อมูลส่วนบุคคล เพื่อสอดคล้องกับนโยบายคุ้มครองข้อมูลส่วนบุคคลของ EU รวมถึงเพื่อรับรองสิทธิของคุณภายใต้นโยบายคุ้มครองข้อมูลส่วนบุคคล
เราอาจทำการแก้ไขเพิ่มเติมเงื่อนไขและข้อกำหนดของนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้เป็นครั้งคราว ซึ่งการแก้ไขเพิ่มเติมดังกล่าวถือว่าเป็นส่วนหนึ่งของนโยบายและข้อกำหนดในนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ด้วย
เรายืนยันว่าคุณจะได้รับการแจ้งให้ทราบหากมีการการปรับปรุงหรือแก้ไขส่วนสำคัญในนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ และจะโพสต์ไปยังเว็บไซต์ในกรณีที่มีการปรับปรุงแก้ไขนโยบาย ก่อนที่การปรับปรุงดังกล่าวจะมีผลบังคับ</p>
									<p><b>การรับรองของเรา</b></p>
									<p>- ข้อมูลของคุณจะถูกนำมาประมวลผล แต่จะเป็นไปโดยชอบด้วยกฎหมาย มีความเป็นธรรม และโปร่งใส</p>
									<p>- ข้อมูลของคุณจะถูกรวบรวมเพื่อวัตถุประสงค์ที่ชัดเจน ชัดแจ้ง และชอบด้วยกฎหมาย และจะไม่ถูกประมวลผลต่อไปในลักษณะที่ไม่สอดคล้องกับวัตถุประสงค์ที่ได้กล่าวไว้</p>
									<p>- ข้อมูลของคุณจะเพียงพอ เกี่ยวเนื่อง และจำกัดเพียงเท่าที่จำเป็นกับวัตถุประสงค์ซึ่งข้อมูลจะถูกประมวลผล</p>
									<p>- ข้อมูลของคุณจะเพียงพอ และในกรณีที่จำเป็นข้อมูลจะถูกเก็บอย่างเป็นปัจจุบัน โดยมีขั้นตอนที่มีเหตุผลเพื่อรับรองว่าข้อมูลของคุณจะมีความถูกต้อง เกี่ยวข้องกับวัตถุประสงค์ในการประมวลผล และจะถูกลบหรือยืนยันโดยไม่ชักช้า</p>
									<p>- ข้อมูลของคุณจะถูกจัดเก็บในรูปแบบที่ไม่อนุญาตให้การระบุถึงตัวตนมีระยะเวลาการเก็บยาวนานกว่าที่จำเป็นเพื่อวัตถุประสงค์ที่ข้อมูลของคุณถูกจัดเก็บเพื่อการประมวลผล</p>
									<p>- ข้อมูลของคุณจะถูกประมวลผลในลักษณะที่มีการรับรองความปลอดภัยที่เหมาะสมสำหรับข้อมูลนั้น รวมถึงการป้องกันต่อการประมวลผลที่ไม่ได้รับอนุญาต หรือละเมิดกฎหมาย และป้องกันต่อการสูญหายโดยอุบัติเหตุ การทำลาย การทำให้เสียหาย โดยใช้เทคนิคที่เหมาะสมหรือวิธีการที่เป็นระบบ</p>
									<p><b>ประเภทของข้อมูลที่จัดเก็บ</b></p>
									<p><b>ข้อมูลส่วนบุคคล</b> หมายถึง ข้อมูลที่เกี่ยวข้องกัยการระบุตัวตนของบุคคลธรรมดา บุคคลธรรมดาที่ระบุตัวตนได้ หมายความถึง บุคคลหนึ่งซึ่งสามารถถูกระบุตัวตนได้โดยเฉพาะเจาะจงโดยตรงหรือโดยอ้อม โดยอ้างอิงจากตัวบ่งชี้ใดๆ</p>
									<p><b>ข้อมูลซึ่งไม่ใช่ข้อมูลส่วนบุคคล</b> หมายถึง ข้อมูลที่ไม่เกี่ยวข้องต่อบุคคลธรรมดาที่ระบุตัวตนได้</p>
									<p><b>ข้อมูลระบบ</b> หมายถึง ข้อมูลที่เราจัดเก็บโดยอัติโนมัติเมื่อคุณล๊อคอินเข้าสู่เว็บไซต์ของเรา ไม่ว่าจะผ่านทางคุ้กกี้ เว็บบีคอน ไฟล์ล๊อคอิน สคริปท์ รวมถึง ข้อมูลทางเทคนิค เช่น ที่อยู่ไอพี ประเภทของบราวซเซอร์ โดเมน ประวัติเว็บไซต์ที่เยี่ยมชม เวลาการเข้าใช้งาน ที่อยู่เว็บไซต์ที่อ้างอิง ข้อมูลเกี่ยวกับสิ่งที่คุณค้นหาหรือที่คุณดูในขณะที่ใช้เว็บไซต์ของเรา</p>
									<p><b>ข้อมูลที่ตั้ง</b> หมายถึง ข้อมูลที่ได้รับจากจีพีเอส ไวไฟ เข็มทิศ เครื่องวัดความเร่ง IP Address หรือโพสต์สาธารณะซึ่งระบุข้อมูลที่ตั้ง</p>
									<p><b>คุ้กกี้</b> หมายถึง ข้อมูลที่ถูกวางในคอมพิวเตอร์ของคุณโดยเว็บเซิร์ฟเวอร์ หลังจากคุ้กกี้ได้ถูกวางในคอมพิวเตอร์ของคุณ คุ้กกี้จะเก็บหรือจดจำข้อมูลของผู้ใช้จนกว่าผู้ใช้จะปิดบราวเซอร์นั้น หรือจนกว่าผู้ใช้จะลบหรือปฏิเสธคุ้กกี้ อย่างไรก็ตาม คุณจะพบว่าจะเป็นการสะดวกในการนำทางการใช้เว็บไซต์ได้อย่างง่ายดาย เพราะคุ้กกี้จะช่วยเก็บข้อมูลเว็บไซต์ซึ่งคุณเยี่ยมชมหรือเปิดขึ้น</p>
									<p><b>หมายเหตุ</b> : ข้อความ <b>“ข้อมูล”</b> ระบุในนโยบายส่วนบุคคลนี้ หมายความถึงข้อมูลทุกประเภทที่ได้กล่าวข้างต้น</p>
									<p>ข้อมูลของคุณดังต่อไปนี้เป็นข้อมูลที่เราจัดเก็บ</p>
									<p>-ชื่อ</p>
									<p>-อีเมล</p>
									<p>-ที่อยู่</p>
									<p>-เบอร์โทรศัพท์</p>
									<p>-หมายเลขประจำเครื่องคอมพิวเตอร์ (IP address)</p>
									<p>-ประเภทบราวเซอร์</p>
									<p>-โดเมน</p>
									<p>-เว็บไซต์ที่เยี่ยมชม</p>
									<p>-เวลาเข้าเว็บไซต์</p>
									<p>-ที่อยู่เว็บไซต์อ้างอิง</p>
									<p>ข้อมูลของคุณดังต่อไปนี้เป็นข้อมูลที่เรา<b>ไม่ได้จัดเก็บ</b></p>
									<p>-ข้อมูลบัตรเครดิต</p>
									<p><b>วัตถุประสงค์ในการประมวลผลข้อมูลของคุณ</b></p>
									<p>การประมวลผลข้อมูลของคุณนั้น หมายถึง การดำเนินการใดๆ หรือชุดกระบวนการดำเนินการใดซึ่งกระทำต่อข้อมูลของคุณไม่ว่าโดยวิธีการอัตโนมัติหรือไม่</p>
									<table className='ba'>
										<tr>
											<th className='ba'>ประเภทข้อมูล</th>
											<th className='ba'>วัตกุประสงค์ในการประมวลผล</th>
										</tr>
										<tr>
											<td className='ba'>
												<p>ข้อมูลส่วนบุคคล</p>
												<p>-ชื่อ</p>
												<p>-อีเมล</p>
												<p>-ที่อยู่</p>
												<p>-เบอร์โทรศัพท์</p>
												<p>-หมายเลขไอพี</p>
											</td>
											<td className='ba'>
												<p>เมื่อคุณเยี่ยมชมเว็บไซต์ของเรา คุณอาจถูกร้องขอให้ลงทะเบียนกับเรา และ/หรือคุณอาจจำเป็นต้องแจ้งข้อมูลส่วนบุคคลของคุณให้กับเรา เพื่อวัตถุประสงค์ในการเก็บข้อมูลเพื่อการตลาดของเรา เช่น</p>
												<p>เพื่อติดต่อและเสนอบริการของเราหรือข้อเสนออื่นๆ ซึ่งคุณอาจเคยแสดงถึงความสนใจไว้กับเรา</p>
												<p>เพื่อแจ้งคุณเกี่ยวกับข่าวสาร ผลิตภัณฑ์ และจดหมายข่าว</p>
												<p>เพื่อตรวจสอบตัวตนของคุณ</p>
												<p>เพื่อรักษาความปลอดภัยต่อบัญชีผู้ใช้ของคุณ</p>
												<p>เพื่อแจ้งคุณเกี่ยวกับการเปลี่ยนแปลงที่จะเกิดขึ้น</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												<p>หมายเลขบัตรเครดิต</p>
											</td>
											<td className='ba'>
												<p>ข้อมูลบัตรเครดิตจะถูกจัดเก็บและถูกตรวจสอบผ่านทางระบบชำระเงินผ่านบุคคลที่สาม ข้อมูลนี้จะไม่ถูกจัดเก็บอย่าถาวรในเซิร์ฟเวอร์ของเรา และจะถูกลบทันทีหลังจากผ่านระบบการตรวจสอบโดยระบบชำระเงินของบุคคลที่สาม</p>
											</td>
										</tr>

										<tr>
											<td className='ba'>
												<p>ข้อมูลซึ่งไม่ใช่ช้อมูลส่วนบุคคล</p>
												<p>-หมายเลขประจำเครื่องคอมพิวเตอร์ (IP address)</p>
												<p>-ประเภทบราวเซอร์</p>
												<p>-โดเมน</p>
												<p>-เว็บไซต์ที่เยี่ยมชม</p>
												<p>-เวลาเข้าเว็บไซต์</p>
												<p>-ที่อยู่เว็บไซต์อ้างอิง</p>
											</td>
											<td className='ba'>
												<p>เพื่อให้ข้อมูลในการเข้าและใช้เว็บไซด์ของเรา</p>
												<p>เพื่อจัดการเว็บไซต์; เพื่อการดำเนินการภายใน แก้ไขปัญหาของเว็บไซต์ วิเคราะห์ข้อมูล ทดสอบ วิจัย เพื่อความปลอดภัย การตรวจสอบการบิดเบือน และการจัดการบัญชีผู้ใช้</p>
												<p>เพื่อแก้ไขและสำรวจปัญหา</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												ข้อมูลระบบ
											</td>
											<td className='ba'>
												<p>เพื่อให้ข้อมูลในการเข้าและใช้เว็บไซต์ของเรา</p>
												<p>เพื่อจัดการเว็บไซต์; เพื่อการดำเนินการภายใน แก้ไขปัญหาของเว็บไซต์ วิเคราะห์ข้อมูล ทดสอบ วิจัย เพื่อความปลอดภัย การตรวจสอบการบิดเบือน และการจัดการบัญชีผู้ใช้</p>
												<p>เพื่อพัฒนาประสบการณ์ของผู้ใช้</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												ข้อมูลที่ตั้ง
											</td>
											<td className='ba'>
												<p>เว็บไซต์จะใช้ข้อมูลที่คุ้กกี้ได้เก็บรวบรวมเพื่อการวิเคราะห์ทางสถิติ หรือกิจกรรมอื่นของอนันดาเพื่อพัฒนาผลิตภัณฑ์และบริการของอนันดาต่อๆไป</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												คุ้กกี้
											</td>
											<td className='ba'>
												<p>เว็บไซต์จะใช้ข้อมูลที่คุ้กกี้ได้เก็บรวบรวมเพื่อการวิเคราะห์ทางสถิติ หรือกิจกรรมอื่นของอนันดาเพื่อพัฒนาผลิตภัณฑ์และบริการของเราต่อๆไป</p>
											</td>
										</tr>
									</table>
									<p><b>ระยะเวลาในการเก็บรักษาข้อมูล</b></p>
									<p>เราจะเก็บรักษาข้อมูลของคุณประเภทต่างๆตามระยะเวลาที่ปรากฎในตารางด้านล่างนี้ อย่างไรก็ตาม คุณมีสิทธิที่จะแจ้งให้เราทราบหากคุณต้องการที่จะลบข้อมูลของคุณไม่ว่าเวลาใด ๆ ก็ตาม</p>
									<table className='ba'>
										<tr>
											<th className='ba'>ประเภทข้อมูล</th>
											<th className='ba'>วัตกุประสงค์ในการประมวลผล</th>
										</tr>
										<tr>
											<td className='ba'>
												<p>ข้อมูลส่วนบุคคล</p>
												<p>-ชื่อ</p>
												<p>-อีเมล</p>
												<p>-ที่อยู่</p>
												<p>-เบอร์โทรศัพท์</p>
												<p>-หมายเลขไอพี</p>
											</td>
											<td className='ba'>
												<p>จนกว่าคุณจะแจ้งให้ลบข้อมูล</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												<p>หมายเลขบัตรเครดิต</p>
											</td>
											<td className='ba'>
												<p>ไม่มีการจัดเก็บข้อมูล</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												<p>ข้อมูลซึ่งไม่ใช่ช้อมูลส่วนบุคคล</p>
												<p>-หมายเลขประจำเครื่องคอมพิวเตอร์ (IP address)</p>
												<p>-ประเภทบราวเซอร์</p>
												<p>-โดเมน</p>
												<p>-เว็บไซต์ที่เยี่ยมชม</p>
												<p>-เวลาเข้าเว็บไซต์</p>
												<p>-ที่อยู่เว็บไซต์อ้างอิง</p>
											</td>
											<td className='ba'>
												<p>จนกว่าคุณจะแจ้งให้ลบข้อมูล</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												ข้อมูลระบบ
											</td>
											<td className='ba'>
												<p>จนกว่าคุณจะแจ้งให้ลบข้อมูล</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												ข้อมูลที่ตั้ง
											</td>
											<td className='ba'>
												<p>จนกว่าคุณจะแจ้งให้ลบข้อมูล</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												คุ้กกี้
											</td>
											<td className='ba'>
												<p>จนกว่าคุณจะแจ้งให้ลบข้อมูล</p>
											</td>
										</tr>
									</table>
									<p><b>การแชร์ข้อมูลของคุณ</b></p>
									<p>ในกรณีที่คุณให้ความยินยอมในการส่งต่อข้อมูลของคุณ เราอาจแชร์ข้อมูลของคุณไปยังบุคคลที่สามซึ่งกำหนดตัวตนได้แน่นอนและมีนโยบายคุ้มครองของมูลส่วนบุคคลตามมาตรฐานเดียวกับนโยบายคุ้มครองข้อมูลส่วนบุคคลดังนี้</p>
									<p>- ผู้ขาย/นายหน้า ในบางกรณี เราอาจทำการแชร์ข้อมูลของคุณต่อผู้ขายหรือนายหน้าซึ่งได้รับอนุญาต ได้แก่ OMISE Co., Ltd เพื่อวัตถุประสงค์เฉพาะเจาะจง เช่น การประมวลผลบัตรเครดิต</p>
									<p>- องค์กรอื่นๆ ที่กฎหมายระบุให้ต้องแชร์ข้อมูลด้วย</p>
									<p><b>ความปลอดภัยของข้อมูลของคุณ</b></p>
									<p>ในการคุ้มครองและป้องกันความเป็นส่วนตัวของข้อมูลส่วนบุคคลของคุณนั้น เว็บไซต์ของเราได้วางมาตรการควบคุมภายในเพื่อกำหนดสิทธิในการเข้าถึงหรือใช้ข้อมูลส่วนบุคคล และเพื่อป้องกัน และให้ความปลอดภัยต่อข้อมูลส่วนบุคคลที่สำคัญ เช่น ข้อมูลหมายเลขบัตรเครดิตของคุณ นอกจากนี้เว็บไซต์ยังได้วางระบบเพื่อคุ้มครองข้อมูลของคุณ เช่น ระบบ Secure Sockets Layer (SSL) protocol เพื่อจัดการความปลอดภัยในการส่งข้อความบนอินเตอร์เนต</p>
    							</div>
    							<br/>
    							<div><b>นโยบายการคืนเงิน</b></div>
    							<div className='f5 ba br3 b--black black tl pa2'>
    								<p><b>บริษัท สินเกียรติธานี จำกัด (บริษัทฯ) ขอแจ้งนโยบายการคืนเงินเพื่อเป็นข้อตกลงและสร้างความเข้าใจเกี่ยวกับการใช้บริการจองห้องพัก,สั่งซื้อสินค้าและบริการ ผ่านทางเว็บไซต์ ดังต่อไปนี้</b></p>
    								<p><b>– บริษัทฯ จะทำการคืนเงินค่าสินค้าให้กับลูกค้า ในกรณีที่บริษัทฯ ไม่สามารถจัดส่งสินค้า หรือ นำเสนอบริการตามที่ลูกค้าสั่งได้</b></p>
    								<p><b>– ในกรณียกเลิกการสำรองห้องพัก</b></p>
    								<p> สำหรับการยกเลิกการสำรองห้องพัก ก่อนวันที่แจ้งจะเข้าพัก 60 วันทำการ บริษัทฯ จะทำการคืนเงินค่าห้องพักเต็มจำนวน</p>
    								<p> สำหรับการยกเลิกการสำรองห้องพัก ก่อนวันที่แจ้งจะเข้าพัก 45 วันทำการ บริษัทฯ จะทำการคืนเงินค่าห้องพัก ร้อยละ 80 ของราคาเต็ม</p>
    								<p> สำหรับการยกเลิกการสำรองห้องพัก ก่อนวันที่แจ้งจะเข้าพัก 30 วันทำการ บริษัทฯ จะทำการคืนเงินค่าห้องพัก ร้อยละ 70 ของราคาเต็ม</p>
    								<p> สำหรับการยกเลิกการสำรองห้องพัก ก่อนวันที่แจ้งจะเข้าพัก 15 วันทำการ บริษัทฯ จะทำการคืนเงินค่าห้องพัก ร้อยละ 60 ของราคาเต็ม</p>
    								<p> สำหรับการยกเลิกการสำรองห้องพัก ก่อนวันที่แจ้งจะเข้าพัก 7 วันทำการ บริษัทฯ จะทำการคืนเงินค่าห้องพัก ร้อยละ 50 ของราคาเต็ม</p>
    								<p> สำหรับการยกเลิกการสำรองห้องพัก หลังวันที่แจ้งจะเข้าพัก 7 วันทำการ บริษัทฯ ขอสงวนสิทธิ์ไม่สามารถขอคืนเงินค่าห้องพักได้</p>
    								<p> สำหรับการยกเลิกการสำรองห้องพักในโครงการ "เราเที่ยวด้วยกัน" บริษัทฯ ขอสงวนสิทธิ์ไม่สามารถขอคืนเงินค่าห้องพักได้</p>
    								<p><b>– ระยะเวลาการคืนเงินค่าสินค้า/บริการ มีรายละเอียดดังนี้</b></p>
    								<p>กรณีลูกค้าชำระเงินเต็มจำนวนหรือผ่อนชำระ ตัดผ่านบัตรเครดิตของธนาคารพาณิชย์ที่จดทะเบียนในประเทศไทย จะทำการคืนเงินกลับไปยังบัตรเครดิตที่ลูกค้าใช้ในการชำระเงิน โดยใช้ระยะเวลาประมาณ 15-20 วันทำการ โดยที่จะขึ้นอยู่กับรอบการตัดบัตรเครดิตของธนาคารเจ้าของบัตรของลูกค้า และ/หรือเป็นไปตามเงื่อนไข/นโยบายการคืนเงินของผู้ให้บริการรับชำระเงิน (PSP)</p>
    								<p>กรณีลูกค้าชำระเงินเต็มจำนวน ตัดผ่านบัตรเดบิตของธนาคารพาณิชย์ที่จดทะเบียนในประเทศไทย จะทำการคืนเงินกลับไปยังบัตรเดบิต ที่ลูกค้าใช้ในการชำระเงิน โดยใช้ระยะเวลาประมาณ 45 – 60 วันทำการ นับจากวันที่ลูกค้าได้รับการแจ้งจากบริษัทฯ โดยที่จะขึ้นอยู่กับเงื่อนไขของธนาคารเจ้าของบัตรของลูกค้า และ/หรือเป็นไปตามเงื่อนไขนโยบายการคืนเงินของผู้ให้บริการรับชำระเงิน (PSP)</p>
    								<p>กรณีลูกค้าชำระเงินเต็มจำนวน ผ่านช่องทางการโอนเงิน จะทำการคืนเงินกลับไปยังเลขที่บัญชีที่ลูกค้าแจ้ง โดยใช้ระยะเวลาประมาณ 15-20 วันทำการ นับจากวันที่ลูกค้าได้รับการแจ้งจากบริษัท (หลังจากบริษัทฯ ได้รับเอกสารประกอบการคืนเงินจากลูกค้าครบถ้วนแล้ว)</p>
    								<p>กรณีลูกค้าชำระเงินเต็มจำนวน ผ่านช่องทางบิลเพย์เม้นท์, ทรูมันนี่ หรือช่องทางการที่จดทะเบียนในประเทศไทย จะทำการคืนเงินกลับไปยังบัตรเดบิต ที่ลูกค้าใช้ในการชำระเงิน โดยใช้ระยะเวลาประมาณ 45 – 60 วันทำการ นับจากวันที่ลูกค้าได้รับการแจ้งจากบริษัทฯ โดยที่จะขึ้นอยู่กับเงื่อนไขของธนาคารเจ้าของบัตรของลูกค้า และ/หรือเป็นไปตามเงื่อนไขนโยบายการคืนเงินของผู้ให้บริการรับชำระเงิน (PSP)</p>
    								<p><b>– กรณีที่ชำระเงินสำเร็จแล้ว หรือ สินค้าถูกจัดส่งแล้ว หรือ เข้ารับบริการแล้ว บริษัทฯ ขอสงวนสิทธิ์ ไม่ดำเนินการยกเลิก คืนเงิน เปลี่ยนแปลง แก้ไข รายการสั่งซื้อสินค้า,บริการ รายการสำรองที่พัก ในกรณีต่างๆ ดังนี้</b></p>
    								<p>กรณีที่เกิดขึ้นจากความผิดพลาดในการสั่งซื้อสินค้า ผิดสี, ผิดรุ่น, ผิดประเภทฯ</p>
    								<p>กรณีที่เกิดขึ้นจากความผิดพลาดในการสำรองห้องพัก,บริการ ผิดประเภทฯ</p>
    								<p>กรณีที่เกิดขึ้นจากการเปลี่ยนแปลงของราคาสินค้าที่อาจเกิดขึ้นได้ในอนาคต</p>
    								<p>รวมถึง Gift Voucher ต่างๆ ทุกกรณีกรณีที่เกิดขึ้นจากความต้องการใส่ส่วนลดเพิ่มเติม, ลืมใส่ส่วนลด, Code</p>
    								<p>กรณีที่เกิดขึ้นจากการเปลี่ยนใจ (Change of mind) ของผู้สั่งซื้อสินค้า,บริการ, สำรองห้องพัก ทุกกรณี</p>
    								<p>กรณีไม่พึงพอใจกับคุณภาพของสินค้าหรือบริการของบริษัทฯ, บุคคล หรือนิติบุคคล อื่นๆ ที่อาจเกี่ยวข้อง</p>
    								<p><b>– บริษัทฯ จะไม่ดำเนินการจัดส่งสินค้า,นำเสนอบริการ และทำการคืนเงิน หากเกิดกรณีต่างๆ ดังนี้</b></p>
    								<p>กรณีที่เกิดขึ้นจากสินค้า/บริการหมด (เช่น สินค้าหมด Stock, สินค้ายกเลิกการผลิตฯ, ห้องพักหมด, ปิดปรับปรุง ฯลฯ)</p>
    								<p>กรณีที่เกิดขึ้นจากราคาสินค้าที่ระบุทางหน้า Website ไม่ถูกต้อง หรือไม่ตรงกับราคาที่จำหน่ายจริง</p>
    								<p>กรณีที่เกิดขึ้นจากเหตุสุดวิสัย ที่อาจเกิดขึ้น</p>
    								<p><b>– บริษัทฯ ขอสงวนสิทธิ์ในการคืนเงินเกินระยะเวลาที่กำหนด หากเกิดเหตุสุดวิสัย</b></p>
    								<p><b>– บริษัทฯ ขอสงวนสิทธิในการเปลี่ยนแปลงเงื่อนไขใดๆ โดยไม่จำต้องแจ้งให้ทราบล่วงหน้า</b></p>
    								<p></p>
    							</div>
    						</div>
		        	:
		        		<div className='flex flex-column'>
		    					<div><b>Privacy Policy</b></div>
		    					<div className='f5 ba br3 b--black black tl pa2'>
    								<p><b>Sinkiat Thani Company Limited’s Privacy Policy</b></p>
    								<p><b>Introduction</b></p>
    								<p>We collect information when you visit our website and use the services available within the website, for example; making an appointment,making a reservation, goods/services purchasing, requesting information, posting photos, submitting your comments. Signing up for membership or using any service where additional information is required.</p>
    								<p><b>Scope of our Privacy Policy</b></p>
    								<p>This Privacy Policy applies to www.sinkiathotel.com, as well as any service accessible on the web platform where this Privacy Policy is applicable. Additional privacy-related terms on such services may be presented for your further approval before you can access to such services.</p>
    								<p><b>Remark</b>: this Privacy Policy does not apply to information that shall be disclosed or submitted to:</p>
    								<p>- services other than those on our website (such as other products or services supplied or operated by us or our affiliates), that do not expressly state that this Privacy Policy applies;</p>
    								<p>- any third party services (including any third party websites) that you may access through our website; or</p>
    								<p>- companies or organizations that advertise products or services on our website.</p>
    								<p><b>Our Restriction</b></p>
    								<p>Our website is not intended for use by children below 16 years old.</p>
    								<p><b>Privacy Policy revision</b></p>
    								<p>This revision of our Privacy Policy is to comply with EU General Data Protection Regulation as well as to ensure your rights under this Privacy Policy.From time to time, We may revise or/and enhance particular policies, terms and conditions of this Privacy Policy which later on shall be deemed as part of the policies and terms of this Privacy Policy.We ensure that for any revision or changes to this Privacy Policy that are important, you will be notified and the revision will be posted on this website before the change becoming effective.</p>
									<p><b>Our Warranties</b></p>
									<p>- Your information shall be processed lawfully, fairly and in a transparent manner;</p>
									<p>- Your information shall be collected for specified, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes;</p>
									<p>- Your information shall be adequate, relevant and limited to what is necessary in relation to the purposes for which they are processed;</p>
									<p>- Your information shall be adequate and where necessary kept up to date; every reasonable step will be taken to ensure that your information that is inaccurate; having regard to the purposes for which they are processed, are erased or rectified without delay;</p>
									<p>- Your information shall be kept in a form which permits identification for no longer than it is necessary for the purpose which your information is processed;</p>
									<p>- Your information shall be processed in a manner that ensures appropriate security of the information including protection against unauthorized or unlawful processing and against accidental loss</p>
									<p><b>Type of information we collect</b></p>
									<p><b>Personal information</b> means information relating to an identifiable natural person; an identifiable natural person is one who can be identified directly or indirectly in particular by referencing to an identifier.</p>
									<p><b>Non-personal information</b> means information which is not relating to an identifiable natural person.</p>
									<p><b>Log information</b> is the information we automatically collect upon your log-in into our website whether through the use of cookies, web beacons, log files, scripts (please refer to more details on our Cookies Policy, including: Technical information (eg: IP address, Browser type , Domain, Visited web pages, Access time, Referring websites addresses, information about what you have searched for and looked at while using our website.)</p>
									<p><b>Location data</b> is information that is derived from your GPS, WiFi, compass, accelerometer, IP address, or public posts that contain location information.</p>
									<p><b>Cookies</b> are tracking information placed on your computer by the web server. After cookies are placed on your computer, cookies will store or memorize the information of the users until the users close the browsers or until the users delete or reject cookies. However, if you choose to use cookies, you will find it more convenient to navigate through the site easily because cookies will help store the information about the websites that you have visited or browsed through.</p>
									<p><b>Remark</b>: the term <b>“Information”</b> contained in this Privacy Policy hereinafter refers to all information mentioned above.</p>
									<p>The following are your information which shall be collected by us;</p>
									<p>-Name</p>
									<p>-Email address</p>
									<p>-Home address</p>
									<p>-Telephone number</p>
									<p>-IP address</p>
									<p>-Browser type</p>
									<p>-Domain</p>
									<p>-Visited web pages</p>
									<p>-Access time</p>
									<p>-Referring websites addresses</p>
									<p>The following are your information which shall <b>be not collected</b> by us;</p>
									<p>-Credit card number</p>
									<p><b>Our purpose on processing of your information</b></p>
									<p>The Processing of your information means any operation or set of operations which is performed on your information whether or not by automated means.</p>
									<table className='ba'>
										<thead>
										<tr>
											<th className='ba'>Type of information</th>
											<th className='ba'>Purposes of the processing</th>
										</tr>
										</thead>
										<tbody>
										<tr>
											<td className='ba'>
												<p>Personal information</p>
												<p>-Name</p>
												<p>-Email address</p>
												<p>-Home address</p>
												<p>-Telephone number</p>
												<p>-IP address</p>
											</td>
											<td className='ba'>
												<p>When you visit our website, you may be requested to register with us and/or provide us with your personal information. The purpose for us to collect this information is mainly for marketing purposes such as;</p>
												<p>to contact you for the offering of our services and other offerings which you may have expressed your interest with us;</p>
												<p>to keep you informed of our news, products, newsletters;</p>
												<p>to verify your identity;</p>
												<p>to secure your account;</p>
												<p>to inform you about upcoming changes.</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												<p>-Credit card number</p>
											</td>
											<td className='ba'>
												<p>Credit card information is collected and the card information verified via a third-party payment processing service. This information is not permanently stored to our servers and is immediately disposed after a verification request has been performed by the third-party payment processing service.</p>
											</td>
										</tr>

										<tr>
											<td className='ba'>
												<p>Non-personal information</p>
												<p>-Browser type</p>
												<p>-Domain</p>
												<p>-Visited web pages</p>
												<p>-Access time</p>
												<p>-Referring websites addresses</p>
											</td>
											<td className='ba'>
												<p>to acknowledge how you access and use our website;</p>
												<p>to administer our website;
for the purpose of our internal operations, figuring out troubleshooting, data analysis, testing, research, security, fraud-detection, and account management.</p>
												<p>to facilitate and investigate your issues</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												Log data
											</td>
											<td className='ba'>
												<p>To acknowledge how you access and use our website;</p>
												<p>to administer our website;</p>
												<p>for the purpose of our internal operations, figuring out troubleshooting, data analysis, testing, research, security, fraud-detection, and account management.</p>
												<p>to improve user experiences</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												Location data
											</td>
											<td className='ba'>
												<p>To provide you with location based service (as the case may be)</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												Cookies
											</td>
											<td className='ba'>
												<p>The website will use the information that cookies have gathered for statistical analysis or other activities of us to further improve the company’s products and services</p>
											</td>
										</tr>
										</tbody>
									</table>
									<p><b>Period of information retention</b></p>
									<p>We will retain your information according to the period as specified in the table below. However, you have the right to inform us of your intention to erase your information at any time.</p>
									<table className='ba'>
										<thead>
										<tr>
											<th className='ba'>Type of information</th>
											<th className='ba'>Period of Retention</th>
										</tr>
										</thead>
										<tbody>
										<tr>
											<td className='ba'>
												<p>Personal information</p>
												<p>-Name</p>
												<p>-Email address</p>
												<p>-Home address</p>
												<p>-Telephone number</p>
												<p>-IP address</p>
											</td>
											<td className='ba'>
												<p>Upon your request to erase the information</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												<p>-Credit card number</p>
											</td>
											<td className='ba'>
												<p>Upon your request to erase the information</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												<p>Non-personal information</p>
												<p>-Browser type</p>
												<p>-Domain</p>
												<p>-Visited web pages</p>
												<p>-Access time</p>
												<p>-Referring websites addresses</p>
											</td>
											<td className='ba'>
												<p>Upon your request to erase the information</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												Log data
											</td>
											<td className='ba'>
												<p>Upon your request to erase the information</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												Location data
											</td>
											<td className='ba'>
												<p>Upon your request to erase the information</p>
											</td>
										</tr>
										<tr>
											<td className='ba'>
												Cookies
											</td>
											<td className='ba'>
												<p>Upon your request to erase the information</p>
											</td>
										</tr>
										</tbody>
									</table>
									<p><b>Sharing of your information</b></p>
									<p>With your consent to transfer your information, we may share your information to certain third parties which shall have its standard of Privacy Policy similar to our Privacy Policy as specified in the following;</p>
									<p>- Vendors /agents : in some circumstance we share your information to our authorized vendor or agent; OMISE Co., Ltd for specific purpose such as credit card processing</p>
									<p>- Other agencies which are required by law.</p>
									<p><b>Security of your information</b></p>
									<p>To preserve and protect the privacy of your personal information, the website has set out the internal control regulations to determine the right to access or use your personal information and to preserve and safeguard important information such as your credit card numbers. The website has put in place the system to protect your information such as the Secure Sockets Layer (SSL) protocol for managing the security of a message transmission on the Internet.</p>
		    					</div>
		    					<br/>
		    					<div><b>Refund Policy</b></div>
		    					<div className='f5 ba br3 b--black black tl pa2'>
    								<p><b>Sinkiat Thani Co., Ltd. Refund Policy</b></p>
    								<p><b>– We will refund only when we cannot make a shipment, provide services as promised.</b></p>
    								<p><b>– Cancellation of Room Reservation</b></p>
    								<p> For cancellation before 60 days of check in date, we will refund 100% of the amount</p>
    								<p> For cancellation before 45 days of check in date, we will refund 80% of the amount</p>
    								<p> For cancellation before 30 days of check in date, we will refund 70% of the amount</p>
    								<p> For cancellation before 15 days of check in date, we will refund 60% of the amount</p>
    								<p> For cancellation before 7 days of check in date, we will refund 50% of the amount</p>
    								<p> For cancellation within 7 days of check in date, the amount will not be refunded.</p>
    								<p><b>– Refund period</b></p>
    								<p>In case client make full payment by Thailand Bank Issued Credit Card, we will process refund within 15-20 business days, according to Client credit card payment due, and/or Payment Service Provider's refund policy.</p>
    								<p>In case client make full payment by Thailand Bank Issued Debit Card, we will process refund within 45-60 business days, according to Client credit card payment due, and/or Payment Service Provider's refund policy.</p>
    								<p>In case client make full payment by Thailand Bank Transfer, we will process refund within 15-20 business days.</p>
    								<p>In case client make full payment by Tesco Lotus Bill Payment, True Wallet, we will process refund within 45-60 business days, according to Client credit card payment due, and/or Payment Service Provider's refund policy.</p>
    								<p><b>– If the products has been delivered, or client already serviced by us, we refrain from refund any amount.</b></p>
    								<p><b>– We will not ship the products or offer services and refund the amount when following;</b></p>
    								<p>In case product out of stock.</p>
    								<p>Rooms are out of order</p>
    								<p>Descriptive price is wrong.</p>
    								<p>In the event of force majeure That may occur</p>
    								<p><b>– The company reserves the right to change any conditions. Without having to give advance notice</b></p>
    								<p></p>
    							</div>
		    				</div>
		        }
	        </div>
	        <button className={`flex-end br3 pa2 bg-gray b--gray dn`} id='closeButtonBtm' onClick={handleClose}>ปิด ｜ CLOSE</button>
	      </section>
	    </div>
	    );	
 }

export default BooknowModal;